import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Layout } from 'antd';
import LayoutPokedex from './component/LayoutPokedex/LayoutPokedex';

function App() {
  return (
    <LayoutPokedex/>
  );
}

export default App;
